import React, { Component } from 'react';
import styled from 'styled-components';
import {
  COLOR_GREEN,
  COLOR_WHITE,
  MAX_WIDTH,
  COLOR_LIGHT_GRAY,
  COLOR_TEXT_GRAY,
  screen,
  COLOR_PITCH,
  COLOR_RED,
  emailRegX,
  phoneNumberRegX,
  Button,
} from '../common/variables';
import { encode } from '../common/functions';
import loaderIcon from '../../images/three-dots.svg';

const Wrapper = styled.div`
  max-width: ${MAX_WIDTH};
  margin: 0 auto;
  padding: 35px;
  .form-container {
    max-width: 700px;
    margin: 0 auto;
    @media ${screen.large} {
      margin: 0;
    }

    &__heading-text {
      color: ${COLOR_GREEN};
      text-align: left;
      font-weight: 400;
      @media ${screen.medium} {
        text-align: center;
      }
      @media ${screen.large} {
        text-align: left;
      }
    }

    &__description {
      max-width: 460px;
      font-size: 1rem;
      margin: 15px auto 30px auto;
      color: ${COLOR_TEXT_GRAY};
      line-height: 30px;
      @media ${screen.large} {
        max-width: 700px;
        font-size: 1.1rem;
        margin: 30px 0 60px 0;
      }
    }

    &__input-container {
      display: flex;
      justify-content: center;
      margin-bottom: 18px;
      @media ${screen.large} {
        justify-content: space-between;
        margin-bottom: 24px;
      }

      label {
        color: ${COLOR_GREEN};
        font-weight: 500;
        font-size: 1.1rem;
        position: relative;
        display: none;
        @media ${screen.large} {
          display: block;
        }
      }

      &__textarea-container {
        width: 100%;
        display: block;
        @media ${screen.medium} {
          margin-bottom: 24px;
          width: 410px;
        }

        @media ${screen.large} {
          width: 430px;
        }
      }

      textarea {
        resize: none;
        width: 100%;
        height: 125px;
        border: 0;
        margin-bottom: 18px;
        display: block;
        outline: none;
        border-radius: 5px;
        padding: 11px;
        background: ${COLOR_LIGHT_GRAY};
        box-sizing: border-box;
        @media ${screen.medium} {
          margin-bottom: 24px;
          padding: 13px;
        }

        &::placeholder {
          color: ${COLOR_TEXT_GRAY};
          font-size: 1rem;
          @media ${screen.large} {
            color: ${COLOR_LIGHT_GRAY};
          }
        }
      }

      button {
        display: block;
        color: ${COLOR_WHITE};
        font-weight: 400;
        padding-left: 50px;
        padding-right: 50px;
        cursor: pointer;
        width: 100%;
        @media ${screen.large} {
          width: auto;
          display: inline-block;
        }
      }
    }

    &__inputs {
      width: 100%;
      border: 0;
      outline: none;
      border-radius: 5px;
      padding: 11px;
      background: ${COLOR_LIGHT_GRAY};
      box-sizing: border-box;
      @media ${screen.medium} {
        padding: 13px;
        width: 410px;
      }

      @media ${screen.large} {
        width: 430px;
      }

      ::placeholder {
        color: ${COLOR_TEXT_GRAY};
        font-size: 1rem;
        @media ${screen.large} {
          color: ${COLOR_LIGHT_GRAY};
        }
      }
    }

    &__inputs--error {
      ::placeholder {
        color: ${COLOR_RED};
        @media ${screen.large} {
          color: ${COLOR_PITCH};
        }
      }
    }

    &__required-text {
      display: block;
      color: ${COLOR_RED};
      position: absolute;
      font-size: 0.8rem;
      margin-top: 3px;
      font-weight: 100;
    }
    &__optional-text {
      display: block;
      color: ${COLOR_TEXT_GRAY};
      position: absolute;
      font-size: 0.8rem;
      margin-top: 3px;
      font-weight: 100;
    }

    &__fix-warning {
      color: ${COLOR_RED};
      font-size: 1rem;
      line-height: 1.4;
      margin-top: 9px;
      text-align: center;
      @media ${screen.large} {
        margin-top: 15px;
        text-align: left;
      }
    }

    &__success-msg {
      color: ${COLOR_GREEN};
      font-size: 1rem;
      line-height: 1.4;
      margin-top: 9px;
      text-align: center;
      @media ${screen.large} {
        margin-top: 15px;
        text-align: left;
      }
    }

    .send-loader {
      width: 40px;
      margin: 20px auto 0 auto;
      display: block;
      @media ${screen.large} {
        width: 52px;
        margin: 0 0 0 20px;
        vertical-align: middle;
        display: inline;
      }
    }
  }
`;

const errorInputStyle = {
  border: `1px ${COLOR_RED} solid`,
  backgroundColor: `${COLOR_PITCH}`,
};

const errorTextStyle = {
  color: `${COLOR_RED}`,
};

class EnquiryForm extends Component {
  state = {
    name: '',
    company: '',
    email: '',
    number: '',
    message: '',
    fixWarning: false,
    successMsg: false,
    errorName: false,
    errorEmail: false,
    errorNumber: false,
    loader: false,
  };

  handleInput = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name, company, email, number, message } = this.state;
    if (!name.length) {
      this.setState({ errorName: true, fixWarning: true });
    } else {
      this.setState({ errorName: false });
    }

    if (!email.length || !emailRegX.test(email)) {
      this.setState({ errorEmail: true, fixWarning: true });
    } else {
      this.setState({ errorEmail: false });
    }

    if (!number.length || !phoneNumberRegX.test(number)) {
      this.setState({ errorNumber: true, fixWarning: true });
    } else {
      this.setState({ errorNumber: false });
    }

    if (
      name.length &&
      email.length &&
      emailRegX.test(email) &&
      number.length &&
      phoneNumberRegX.test(number)
    ) {
      // if all the values are valid do the action here
      this.setState({ loader: true });
      const inputs = {
        person_name: name,
        company_name: company,
        email: email,
        contact_number: number,
        delivery_suburb: '',
        message: message,
      };
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'General Enquiry', ...inputs }),
      })
        .then(() => {
          this.setState({
            name: '',
            company: '',
            email: '',
            number: '',
            deliverySuburb: '',
            message: '',
            fixWarning: false,
            successMsg: true,
            loader: false,
          });
        })
        .catch(error => alert(error));
    } else {
      this.setState({ successMsg: false });
    }
  };

  render() {
    const text = this.props.text;
    const {
      name,
      email,
      number,
      company,
      message,
      errorName,
      errorNumber,
      errorEmail,
      fixWarning,
      successMsg,
      loader,
    } = this.state;

    return (
      <Wrapper>
        <h2 className="form-container__heading-text">Enquire Now</h2>
        <p className="form-container__description">{text}</p>
        <div className="form-container">
          <form
            onSubmit={this.handleSubmit}
            name="General Enquiry"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div className="form-container__input-container">
              <label style={errorName ? errorTextStyle : null}>
                Your name
                {errorName ? (
                  <span className="form-container__required-text">
                    Required
                  </span>
                ) : null}
              </label>
              <input
                className={
                  errorName
                    ? 'form-container__inputs form-container__inputs--error'
                    : 'form-container__inputs'
                }
                onChange={this.handleInput}
                value={name}
                id="name"
                type="text"
                placeholder="Your name*"
                style={errorName ? errorInputStyle : null}
                name="person_name"
              />
            </div>
            <div className="form-container__input-container">
              <label>Company name</label>
              <input
                className="form-container__inputs"
                onChange={this.handleInput}
                value={company}
                id="company"
                type="text"
                placeholder="Company name"
                name="company_name"
              />
            </div>
            <div className="form-container__input-container">
              <label style={errorEmail ? errorTextStyle : null}>
                Email
                {errorEmail ? (
                  <span className="form-container__required-text">
                    Required
                  </span>
                ) : null}
              </label>
              <input
                className={
                  errorEmail
                    ? 'form-container__inputs form-container__inputs--error'
                    : 'form-container__inputs'
                }
                onChange={this.handleInput}
                value={email}
                id="email"
                type="text"
                placeholder="Email*"
                style={errorEmail ? errorInputStyle : null}
                name="email"
              />
            </div>
            <div className="form-container__input-container">
              <label style={errorNumber ? errorTextStyle : null}>
                Contact number
                {errorNumber ? (
                  <span className="form-container__required-text">
                    Required
                  </span>
                ) : null}
              </label>
              <input
                className={
                  errorNumber
                    ? 'form-container__inputs form-container__inputs--error'
                    : 'form-container__inputs'
                }
                onChange={this.handleInput}
                value={number}
                id="number"
                type="text"
                placeholder="Contact number*"
                style={errorNumber ? errorInputStyle : null}
                name="contact_number"
              />
            </div>
            <div className="form-container__input-container">
              <label>Message</label>
              <span className="form-container__input-container__textarea-container">
                <textarea
                  onChange={this.handleInput}
                  value={message}
                  id="message"
                  data-gramm_editor="false"
                  placeholder="Message"
                  name="message"
                />
                <Button>Send</Button>
                {loader ? (
                  <img
                    className="send-loader"
                    src={loaderIcon}
                    alt="Loading..."
                  />
                ) : null}
                {fixWarning ? (
                  <p className="form-container__fix-warning">Required fields</p>
                ) : null}
                {successMsg ? (
                  <p className="form-container__success-msg">
                    Thank you for contacting us! Your message has been
                    successfully sent and someone from our team will be in touch
                    shortly.
                  </p>
                ) : null}
              </span>
            </div>
          </form>
        </div>
      </Wrapper>
    );
  }
}

export default EnquiryForm;
