import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { removeHTMLTags } from '../common/functions';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  screen,
  COLOR_LIGHT_GRAY,
  COLOR_GREEN,
  COLOR_DIRTY_WHITE,
  Button,
  StyledLink,
} from '../common/variables';

const Wrapper = styled.div`
  background: ${COLOR_DIRTY_WHITE};
  padding: 30px 0;

  .mobilehidden {
    display: none;
    @media ${screen.medium} {
      display: flex;
    }
  }
  .read-more-articles {
    button {
      margin: 0 auto;
    }
    @media ${screen.medium} {
      display: none;
    }
  }
  @media ${screen.large} {
    padding: 60px 0;
  }
  @media ${screen.largeXL} {
    padding: 90px 0;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1170px;
    margin: 0 auto;
    text-align: center;
    padding: 0px 35px;
    @media ${screen.medium} {
      margin: 0;
      padding: 0px 50px;
    }
    @media ${screen.large} {
      margin: 0 15px;
    }
    @media ${screen.largeXXL} {
      margin: 0 auto;
      justify-content: space-between;
    }

    &__card {
      width: 100vw;
      margin-bottom: 15px;
      background: ${COLOR_LIGHT_GRAY};
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      @media ${screen.medium} {
        width: 310px;
        margin: 15px 0 15px 0;
      }
      @media ${screen.large} {
        margin: 20px 0 20px 0;
        padding-bottom: 30px;
      }
      @media ${screen.largeXL} {
        width: 355px;
      }
      @media ${screen.largeXXL} {
        margin: 0;
      }

      &:last-child {
        @media ${screen.large} {
          display: none;
        }
        @media ${screen.largeXL} {
          display: flex;
        }
      }
    }

    &__image {
      @media ${screen.medium} {
        max-width: 400px;
      }
    }

    &__headline-txt {
      margin: 10px 0;
      font-size: 1rem;
      @media ${screen.medium} {
        margin: 15px 0;
        font-size: 1.1rem;
      }
    }

    &__title {
      color: ${COLOR_GREEN};
      font-size: 1.2rem;
      padding: 0 20px;
      margin: 14px 0 0 0;
      font-weight: 400;
      @media ${screen.medium} {
        font-size: 1.25rem;
      }
      @media ${screen.largeXL} {
        margin: 20px 0 10px 0;
        font-size: 1.35rem;
      }
    }

    &__description {
      margin: 5px 0 0 0;
      padding: 0 15px 10px 15px;
      word-break: break-word;
      font-size: 18px;
      line-height: 27px;
      @media ${screen.medium} {
        font-size: 0.9rem;

        line-height: 30px;
        padding: 0 12px 10px 12px;
      }
      @media ${screen.large} {
        font-size: 1rem;
        margin: 0 0 0 0;
        padding-bottom: 10px;
      }
      @media ${screen.largeXL} {
        font-size: 0.9rem;
        line-height: 1.4rem;
        margin: 0 20px 0 20px;
        padding-bottom: 20px;
      }
    }

    .links {
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-left: 60px;
        margin-right: 60px;
        display: block;
      }
    }
  }
`;

const FeaturedSection = ({ content }) => {
  if (!content || content.length === 0) return null;
  const renderContent = content.map((item, index) => {
    return (
      <div
        className={`items__card ${index !== 0 ? 'mobilehidden' : ''}`}
        key={item.node.id}
      >
        {item.node.acfBlog.featuredImage.localFile && (
          <div className="items__image">
            <Link to={`/blog/${item.node.slug}`}>
              <GatsbyImage
                image={
                  item.node.acfBlog.featuredImage.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={
                  item.node.acfBlog.featuredImage.altText ||
                  'Source Separation Systems'
                }
              />
            </Link>
          </div>
        )}

        <Link to={`/blog/${item.node.slug}`}>
          <h2
            className="items__title"
            dangerouslySetInnerHTML={{ __html: item.node.title }}
          />
        </Link>

        <p className="items__description">
          {removeHTMLTags(item.node.acfBlog.content)
            .split(' ')
            .slice(0, 31)
            .join(' ')
            .includes('https')
            ? removeHTMLTags(item.node.acfBlog.content).slice(0, 145) + '....'
            : removeHTMLTags(item.node.acfBlog.content)
                .split(' ')
                .slice(0, 31)
                .join(' ') + '....'}
        </p>

        <StyledLink className="links" to={`/blog/${item.node.slug}`}>
          Read more
        </StyledLink>
      </div>
    );
  });
  return (
    <Wrapper>
      <div className="items">{renderContent}</div>
      <div className="read-more-articles">
        <Link to="/blog">
          <Button>Read More Articles</Button>
        </Link>
      </div>
    </Wrapper>
  );
};

export default FeaturedSection;
